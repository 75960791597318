import { useState, useEffect  } from "react";
import "./App.css";

import { InputPicker, Button } from 'rsuite';
import { DatePicker } from 'rsuite';
import ReactEcharts from "echarts-for-react"; 
import moment from 'moment' ;
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

//const BaseAPI = 'https://api.revemu.org/flowrate_api.php'

const BaseAPI = 'http://119.76.182.199:48080/aquas/api/flowrate_api.php'


const Graph= () => {


  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  const [LogTable, setTable] = useState(8);
  const [DateType, setDateType] = useState('1d');

  const OnDateTypeChange = event => {
    event.persist();
    
    setDateType(event.target.value);
    //console.debug(`${event.target.name}: ${event.target.value}`);
  };

  const OnStartDateChange = (date) => {
    setStartDate(date)
  };

  const OnEndDateChange = (date) => {
    setEndDate(date)
  };

  const OnTableChange = (value) => {
    setTable(value)
  };

  const OnSelectTypeChange = (value) => {
    setDateType(value)
  };

  function getTime(item) {
    return [item.datetime].join(" ");
  }

  function getWaterLevel(item) {
    return [item.waterlevel].join(" ");
  }

  function getflowrate(item) {
    return [item.flowrate].join(" ");
  }

  async function fetchDataJSON(url, devices) {
    const response = await fetch(url);
    const name = devices[0]['name'] ;
    //console.log(devices) ;
    let datax = [];
    let datay = [];
    let datay2 = [];
    try {
      const data = await response.json() ;
      
      datax = data.map(getTime) ;
      datay = data.map(getWaterLevel) ;
      datay2 = data.map(getflowrate) ;
      
      //setLog(data);
      //setDataX(DataLog.map(getTime)) ;
      //setDataY(DataLog.map(getWaterLevel)) ;
      //setDataY2(DataLog.map(getflowrate)) ;

      

      //return data ;
    } catch (error) {
      console.log('Fetch error: ', error);
      //return 0;
    }
    
    const New_OPTION = {
      title: {
        text: name
      },
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
      
        data: datax
      },
      yAxis: [
        {
          type: 'value'
        }, {
          type: 'value'
        }
      ],
      dataZoom: [],
      series: [
        {
          name: 'ระดับน้ำ',
          type: 'line',
      
          showSymbol: false,
          data: datay
        },
        {
          name: 'อัตราการไหล',
          type: 'line',
          yAxisIndex: 1,
          showSymbol: false,
          data: datay2
        }
      ]
    };

    setOption(New_OPTION) ;
    return 1 ;
  }
  
  const DEFAULT_OPTION = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'none',
        axisPointer: {
            type: 'cross'
        }
    },
    xAxis: {
   
      data: [],
      axisLabel: {
          formatter: function (value, idx) {
        // Formatted to be month/day; display year only in the first label
        var date = new Date(value);
        var mydate = date.getYear()+1900 + "-" 
        if ((date.getMonth() + 1) < 10) {
          mydate = mydate + "0"  
        }
        mydate = mydate + (date.getMonth() + 1) + "-" ;

        if ((date.getDate() + 1) < 10) {
          mydate = mydate + "0"  
        }
        mydate = mydate + (date.getDate()) + " ";

        if ((date.getHours() + 1) < 10) {
          mydate = mydate + "0"  
        }
        mydate = mydate + date.getHours() + ":" ;

        if ((date.getMinutes() + 1) < 10) {
          mydate = mydate + "0"  
        }
        mydate = mydate + date.getMinutes()  ;


        //var texts = [date.getYear()+1900,(date.getMonth() + 1), date.getDate()];
        //if (idx === 0) {
        //    texts.unshift(date.getYear());
        //}
        //return texts.join('-');
        return mydate;
        }
      }
    },
    yAxis: [
      {
        
        type: 'value',
        name: "ระดับน้ำ",
        nameLocation: "middle",
        nameRotate: 90,
        nameGap: 40

      }, {
        type: 'value',
        name: "อัตราการไหล",
        nameLocation: "middle",
        nameRotate: -90,
        nameGap: 40
        
      }
    ],
    legend: {
      icon: "rect",
      data:['ระดับน้ำ', 'อัตราการไหล'],
      formatter: function (name) {
        if (name == 'ระดับน้ำ') {
          return name + ' (ม.)';
        } else {
          return name + ' (ลบ.ม./วิ)';
        }
        
    }
  },
    dataZoom: [],
    series: [
      {
        name: 'ระดับน้ำ',
        type: 'line',
        
        showSymbol: false,
        data: []
      },
      {
        name: 'อัตราการไหล',
        type: 'line',
        yAxisIndex: 1,
        showSymbol: false,
        data: []
      }
    ]
  };

  const [option, setOption] = useState(DEFAULT_OPTION);

  function FetchGraph() {
    var start_d ;
    var end_d = new Date() ;
    
    switch(DateType) {
        case '0d':
          start_d = StartDate ;
          end_d = EndDate ;
          break ;
        case '2d':
          start_d = moment(end_d, "YYYY-MM-DD").subtract(2, 'days');
          break ;
        case '7d':
          start_d = moment(end_d, "YYYY-MM-DD").subtract(7, 'days');
          break ;
        case '1m':
          start_d = moment(end_d, "YYYY-MM-DD").subtract(1, 'months');
          break ;
        case '1y':
          start_d = moment(end_d, "YYYY-MM-DD").subtract(1, 'years');
          break ;
        default:
          start_d = moment(end_d, "YYYY-MM-DD").subtract(1, 'days');
          break ;
    }
      
      start_d = moment(start_d).format("YYYY-MM-DD") ;
      end_d = moment(end_d).format("YYYY-MM-DD") ;
    
    
    let devices = posts.filter(posts => posts.dvi_id == LogTable) ;
    //console.log(devices) ;
    //console.log( devices[0]['dvi_log_table']) ;
    let log_table_name =  devices[0]['dvi_log_table'] ;
    //console.log(devices[0]['FlowRate']['ext_tag_column']) ;
    let flowrate_map = devices[0]['WaterFlowRate']['ext_tag_column'] ;
    //console.log(devices[0]['MB_TYPE8DEVICE2Data']['ext_tag_column']) ;
    let water_level_map = devices[0]['WaterLevel']['ext_tag_column'] ;
    
    
    let url = BaseAPI + '?cmd=query_log&start_date=' + 
    start_d + '&end_date=' + end_d + '&log_table=' + log_table_name + '&flowrate=' + flowrate_map + '&water_level_map=' + water_level_map;
    
    
      fetchDataJSON(url, devices).then(data => {
       

        //console.log(data) ;
       
      });

  }

  const OnSubmit= () => {
    
    FetchGraph() ;

};

  const [posts, setPosts] = useState([]);

  async function fetchStartData(url) {
    const response = await fetch(url);
    
    try {
      const data = await response.json() ;
      setPosts(data);
      //setTable(data[0].dvi_id)
      //console.log(data[0].dvi_id);
      return data ;
    } catch (error) {
      console.log('Fetch error: ', error);
      return 0;
    }
    
    
  }

  useEffect(() => {
      
         let data = fetchStartData(BaseAPI + '?cmd=query') ;
         const queryParameters = new URLSearchParams(window.location.search)
         let device_id = 7 ;
         device_id = queryParameters.get("id")
         //console.log("parameter id=" + device_id)
         if (device_id > 0) {
            setTable(device_id)
         }
        
   }, []);

   useEffect(() => {
      if (posts.length > 0) { FetchGraph() ; }
   }, [posts]);


   const display = [{
    "label": "กราฟ",
    "value": "graph",
   }]

   const SelectType = [
    {
    "label": "1 วัน",
    "value": "1d",
   },
   {
    "label": "2 วัน",
    "value": "2d",
   },
   {
    "label": "7 วัน",
    "value": "7d",
   },
   {
    "label": "1 เดือน",
    "value": "1m",
   },
   {
    "label": "1 ปี",
    "value": "1y",
   },
   {
    "label": "กำหนดเอง",
    "value": "0d",
   },
  ]

   let cdevice = posts.filter(posts => posts.dvi_id == LogTable) ;

   const [open, setOpen] = useState(false);

   const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

   const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <div>
    <Box sx={{ display: 'flex', flexDirection: 'row', bgcolor: '#cfe8fc', justifyContent: 'left', padding: "9px", width: "100%" }} >
      
      <Box sx={{ flexShrink: 1, bgcolor: 'white', border: 1, width: 150 }}>
      
      
      {posts.map((post, index) => (
        
        <ListItem key={index} component="div" disablePadding >
          <ListItemButton onClick={() => setTable(post.dvi_id) }>
            <ListItemText primary={post.name} />
            
          </ListItemButton>
        </ListItem>
       
      ))}


      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: "9px", width: "90%" }}>
      
           <Box sx={{ bgcolor: 'white', width: '100%', border: 1, padding: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', border: 0}}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', border: 0, width: '70%'}}>
                          <Box sx={{ paddingLeft: 1 }}>เลือกสถานี</Box>
                          <Box sx={{ paddingLeft: 1 }}><InputPicker key={LogTable} onChange={OnTableChange} defaultValue={LogTable.toString()} data={ posts.map (item => ({ label: item.name, value: item.dvi_id })) } style={{ width: 224 }} /></Box>
                          <Box sx={{ display: 'flex', flexDirection: 'row', padding: 1}}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                                    <Box>ตั้งแต่วันที่</Box>
                                    <Box><DatePicker oneTap value={StartDate} onChange={OnStartDateChange}/> </Box>
                                  </Box> 
                                  <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 2 }}>
                                    <Box>ถึงวันที่</Box>
                                    <Box><DatePicker oneTap value={EndDate} onChange={OnEndDateChange}/> </Box>
                                  </Box> 
                                  <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 2}}>
                                    <Box>การแสดงผล</Box>
                                    <Box><InputPicker  defaultValue={'graph'} data={display} style={{ width: "100%" }} /></Box>
                                  </Box> 
                          </Box>
                          <Box sx={{ paddingLeft: 1 }}>ข้อมูลย้อนหลัง</Box>
                          <Box sx={{ paddingLeft: 1 }}><InputPicker onChange={OnSelectTypeChange}  defaultValue={'1d'} data={SelectType} style={{ width: 224 }} /></Box>
                          <Box sx={{ display: 'flex', flexDirection: 'row', padding: 1}}>
                                <Box sx={{ width: '70%', display: 'flex', flexDirection: 'column'}}>
                                      
                                      <Box sx= {{paddingTop: 3}}><Button appearance="primary" onClick={OnSubmit}>Submit</Button></Box>
                                </Box>
                                
                          </Box> {/* End Form Box */}
                          <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="child-modal-title"
                              aria-describedby="child-modal-description"
                            >
                              <Box sx={{ ...style, width: "auto" }}>
                                
                                <p id="child-modal-description">
                                <img src="./table07.jpg" width="100%" height="100%" alt="logo"></img>
                                </p>
                              </Box>
                          </Modal>
                        </Box>
                        <Box  sx={{ width: '30%', display: 'flex', flexDirection: 'column', padding: 1, justifyContent: 'center', border: 0}}>
                        
                        <Card sx={{ width: "auto" }}>
                              <CardMedia
                                sx={{ height: 250}}
                                image="./station07.jpg"
                                title={`${cdevice.length > 0 && "สถานที่ติดตั้ง " + cdevice[0].name}`}
                              />
                             
                              
                          </Card>
                              
                          
                        </Box>
                        <Box sx={{ width: '30%', display: 'flex', flexDirection: 'column', padding: 1, justifyContent: 'center', border: 0}}>
                       
                          <Card sx={{ width: "auto" }}>
                              <CardMedia onClick={handleOpen}
                                sx={{ height: 250 }}
                                image="./table07.jpg"
                                title="คลิกเพื่อดูรายละเอียด"
                              />
                             
                              
                          </Card>
                        </Box>
                </Box>
               
           </Box>
           <Box sx={{ bgcolor: 'white', width: '100%', paddingTop: 2  }}>
              <ReactEcharts option={option} />
           </Box>
      </Box>
       
        
    </Box>
   
    </div>
    
    
  );
};

export default Graph;