/* global google */
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { useState, useEffect  } from "react";
import "./App.css";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';


const center = {
  lat: 18.641482,
  lng: 98.845173
};



   const PinCard = (props) => (
   
    <Card sx={{ width: 250 }}>
      
      <Box
          sx={{
          width: '100%',
          height: 30,
          backgroundColor: '#009ddc',
          '&:hover': {
              backgroundColor: 'primary.main',
              opacity: [0.9, 0.8, 0.7],
          },
          }}
      >
          <Typography sx={{ fontSize: 12, padding: 1 }} color="white" gutterBottom>
            {props.name}
          </Typography>
        </Box>
        
        <Box sx={{width: '100%', height: 30, backgroundColor: 'white'}}>
          <Typography sx={{ fontSize: 12, padding: 1 }} color="black" gutterBottom>
            ระดับน้ำ {props.level} ม.
          </Typography>
        </Box>
        <Box sx={{width: '100%', height: 30, backgroundColor: 'white'}}>
          <Typography sx={{ fontSize: 12, padding: 1 }} color="black" gutterBottom>
            อัตราการไหล {props.flowrate} ลบ.ม./วิ
          </Typography>
        </Box>
        <Box sx={{width: '100%', height: 30, backgroundColor: '#F2F4F4'}}>
          <Typography sx={{ fontSize: 12, padding: 1 }} color="black" gutterBottom>
            ข้อมูลล่าสุด {props.last_update} 
          </Typography>
        </Box>
        
    
    </Card>
    
);



const App = () => {

  

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDmSN-Qjr5c_779P_uIydW-rEJ5HaT4lJQ",
  });
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  
  const [infoWindowData, setInfoWindowData] = useState();
  
  const [posts, setPosts] = useState([]);

  function FetchAPI() {
    //fetch('https://api.revemu.org/flowrate_api.php?cmd=query')
    fetch('http://119.76.182.199:48080/aquas/api/flowrate_api.php?cmd=query')
    .then((response) => response.json())
    .then((data) => {
       //console.log(data);
       setPosts(data);
       
    })
    .catch((err) => {
       console.log(err.message);
    });
  }

  useEffect(() => {
    FetchAPI() ;
    const interval = setInterval(() => {
      FetchAPI() ;
    }, 10000);
    return () => clearInterval(interval);
    }, []);

  const handleMarkerClick = (id, lat, lng, name, device) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, name, device});
    setIsOpen(true);
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  function returnCard(post) {
    return ( <PinCard 
    name={post.name} 
    level={post['WaterLevel']['tag_value']} 
    flowrate={post['WaterVelocity']['tag_value']}
    last_update={post['WaterLevel']['tag_last_update']} 
    /> )
  }

  return (
   

    <Box sx={{ display: 'flex', bgcolor: '#cfe8fc', height: '60vh', justifyContent: 'center', flexWrap: 'wrap', padding: "3px" }} >
      <Box > 
          
        {posts.map((post, index) => (
        
        <div key={"x" + index} className='card'>
          
          { (index % 2 === 0) &&

                  returnCard(post)
              
          }
        </div>
    ))}
          
          </Box>
         <Box sx={{paddingLeft: 2, paddingRight: 2}}>
          

      
     
     {!isLoaded ? (
     <h1>Loading...</h1>
   ) : (
     <GoogleMap
       mapContainerClassName="map-container"
       center={center}
       zoom={6}
       
       onClick={() => setIsOpen(false)}
     >
       {posts.map((post, ind) => (
        

         <Marker
           key={ind}
           position={ {lat: Number(post.lat), lng: Number(post.lng)  }}
           onMouseOver={() => {
             handleMarkerClick(ind, Number(post.lat), Number(post.lng), post.name, post);
           }}
           onClick={() => {
            openInNewTab('/Graph?id=' + post.dvi_id);
           }}
         >
          
           {isOpen && infoWindowData?.id === ind && (
             <InfoWindow
               onCloseClick={() => {
                 setIsOpen(false);
               }}
             >
                    <div>
                    <div><strong>Name</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={`./Graph?id=${infoWindowData.device.dvi_id}`}>{infoWindowData.device.name}</a></div>
                    <div><strong>ระดับน้ำ</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{infoWindowData.device['WaterLevel']['tag_value']} ม.</div>
                    <div><strong>อัตราการไหล</strong>&nbsp;&nbsp;&nbsp;{infoWindowData.device['WaterFlowRate']['tag_value']} ลบ.ม./วิ</div>
                    <div><strong>Lat,Lng</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{infoWindowData.device.lat}, {infoWindowData.device.lng}</div>
                    </div>
             </InfoWindow>
           )}
         </Marker>

       
       ))}
     </GoogleMap>
   )}
     
  
 

          
        </Box>
        <Box>
          
          {posts.map((post, index) => (
        
        <div key={"z" + index} className='card'>
          
          { (index % 2 == 1) &&
                returnCard(post)
          }
        </div>
    ))}
          
          </Box>
    
          </Box>

    
  );
};

export default App;