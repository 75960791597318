import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


import Dashboard from './Dashboard';
import Report from './Report';


function HeaderBar() {
  return (
    <AppBar position="static">
      <Container>
        <Toolbar disableGutters>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Typography
            
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              flexGrow: 1,
              fontSize: 16,
              fontWeight: '700',
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Home
          </Typography>

          <Typography
            
            noWrap
            component="a"
            href="/Graph"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              flexGrow: 1,
              fontSize: 16,
              fontWeight: '700',
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Graph
          </Typography>
        </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function SwitchPage() {
  const projectPath = window.location.pathname ;

  switch(projectPath) {
      case '/Graph':
        return  <Report />;
      case 'projectTwo':
          break ;
      default:
          return  <Dashboard />;
  }
}

function ResponsiveAppBar() {

  return (
    
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: 1}}>
      <Box sx={{ bgcolor: '#cfe8fc', width: '100%', border: 0.5 }} ><img src="./header.png" width="100%" height="100%" alt="logo"></img> </Box>
      <Box sx={{ bgcolor: '#cfe8fc', width: '100%' }} >{HeaderBar()}</Box>
      <Box sx={{ bgcolor: '#cfe8fc', width: '100%', border: 0.5 }} ><SwitchPage  /></Box>
    </Box>
  

  );
}
export default ResponsiveAppBar;
