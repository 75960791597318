import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
//import './index.css';
//import App from './App';
//import Graph from './Graph';
import Start from './Start';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { StyledEngineProvider } from '@mui/material/styles';


const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  


   
<div className="center">
   <Start />
   </div>






     

    
  
);
